.new{
    background-color: #67d7cc;
    background: repeating-linear-gradient(#f1ede9, #f1ede9 31px, #94acd4 31px, #94acd4 32px);
    min-height: 500px;
    width: 90%;
    margin: 0 auto;
    display: none;
    position: relative;

    /*en  pruebas*/

   
    border-radius: 10px;
    padding: 1rem;
    flex-direction: column;
    justify-content: space-around;
    white-space: pre-wrap;
    position: relative;
    z-index: 1;
    
    
}
textarea{
    box-sizing: border-box;
    border: none;
    resize: none;
    background-color: transparent;
    outline: none;
    padding-top: 21px;
    line-height: 32px;
    font-size: 20px;
    min-height: 300px;
    width: 100%;
    padding: 27px 20px 0px 20px;
    font-family: 'Comic Neue', cursive;
    margin-top: 2rem;
}
.btn{
    border: none;
    background-color: black;
    border-radius: 15px;
    padding: 1rem;
    cursor: pointer;
    transition: all .2s ease-in-out;
    text-transform: uppercase;
    font-family:  Montserrat, sans-serif;
    font-weight: bold;
    color: white;
    box-shadow: 2px 2px 10px black;
    width: 100px;
}
.btn:hover{
    background-color: #FEF68A;
    color: black;
}
.inputAddNote{
    background-color: rgb(232, 232, 232);
    padding: 1rem;
    border: none;
    outline: none;
    border-radius: 10px;
    box-shadow: 0 0 10px black;
    box-sizing: border-box;
    position: absolute;
    top: 1rem;
    width: 90%;
    font-size: 1.4rem;
}
.inputAddNote::placeholder{
    color: rgb(60, 60, 60);
    font-weight: bold;
    font-family: 'Comic Neue', cursive;
    font-size: 1rem;
}
/* ----------------------------------------------
  Generated by AnimatiSS
  Licensed under FreeBSD License
  URL: https://xsgames.co/animatiss
  Twitter: @xsgames_
---------------------------------------------- */

.shake-right{animation:shake-right 0.4s linear both} 
@keyframes shake-right{
    0%,100%{
        transform:rotate(0deg);transform-origin:100% 50%
    }
    10%{
        transform:rotate(2deg)
    }
    20%,40%,60%{
        transform:rotate(-4deg)
    }
    30%,50%,70%{
        transform:rotate(4deg)
    }
    80%{
        transform:rotate(-2deg)
    }
    90%{
        transform:rotate(2deg)
    }
}
.wobble-horizontal-top{animation:wobble-horizontal-top .8s linear both} @keyframes wobble-horizontal-top{0%,100%{transform:translateX(0);transform-origin:50% 50%}15%{transform:translateX(-30px) rotate(6deg)}30%{transform:translateX(15px) rotate(-6deg)}45%{transform:translateX(-15px) rotate(3.6deg)}60%{transform:translateX(9px) rotate(-2.4deg)}75%{transform:translateX(-6px) rotate(1.2deg)}}

@media (min-width: 768px) { 
    .new{
        width: 620px;
        /*en  pruebas*/
    }
    .inputAddNote{
        width: 620px
    }
    .inputAddNote::placeholder{
        font-size: 1.2rem;
    }
}