footer{
    margin-top: 4rem;
    padding-bottom: 2rem;
}
.footer-wrapper{
    display: flex;
    justify-content: center;
    align-items: center;
    flex-direction: column;
    gap: 1rem;
}
.footer-wrapper p{
    color: white;
    font-family: 'Montserrat', sans-serif;
    text-shadow: 1px 1px 2px var(--primary);
}
.footer-wrapper .icons{
    color: white;

}
.socials-wrapper{
    display: flex;
    width: 90%;
    justify-content: center;
    gap: 1.5rem;
}
.linkedin-icon{
    filter: drop-shadow(0 0 3px rgb(55, 151, 252));
}
.github-icon{
    filter: drop-shadow(0 0 3px rgb(0, 212, 145));
}
.port-icon{
    filter: drop-shadow(0 0 3px rgb(255, 182, 25));
    font-size: 1.7rem;
}
/* .position-center{
    position: relative;
    bottom: 200px;
    padding-bottom: 0;
} */


