@import url('https://fonts.googleapis.com/css2?family=Blaka&family=Comic+Neue:wght@300;400;700&family=Montserrat:wght@100;300;400;500;600;700;900&family=Permanent+Marker&display=swap');

@import '../styles/root.css';


.header .title{
    font-size: 2.5rem;
    font-family: 'Comic Neue', cursive;
}
.header{
    position: relative;
    display: flex;
    justify-content: center;
}
.logo-wrapper{
    width: 50%;
    display: flex;
    justify-content: center;
    align-items: center;
    padding: 1.5rem;
    margin: 0 auto;

}
.logo-wrapper p{
    color: white;
    font-family: 'Montserrat', sans-serif;
    text-shadow: 2px 2px 2px var(--primary);
}
.logo-wrapper img{
    max-width: 100%;
    object-fit: cover;
    filter: drop-shadow(0px 0px .2rem var(--secondary));
   
}
@media (min-width: 769px) { 
    .logo-wrapper{
        width: 500px;
        margin-bottom: 1rem;
        align-items: center;
    }
    .logo-wrapper img{
        max-width: 50%;
        position: relative;
    
    }

}
