@import url('https://fonts.googleapis.com/css2?family=Blaka&family=Comic+Neue:wght@300;400;700&family=Montserrat:wght@100;300;400;500;600;700;900&family=Permanent+Marker&display=swap');

.search{
    width: 100%;
    margin: 2rem auto;
    display: flex;
    align-items: center;
    justify-content: center;
    gap: 2rem;
    flex-direction: column;
}
.input-wrapper{
    position: relative;
    width: 80%;
    display: flex;
    align-items: center;
}
.search input{
    width: 100%;
    padding: 1rem;
    border-radius: 10px;
    outline: none;
    border: none;
    box-shadow: 0 0 2px black;
    background-color: rgb(1, 1, 1, .8);
    color: white;
 

}
.search input::placeholder{
    color: rgb(188, 188, 188);
    font-family: 'Montserrat', sans-serif;
}
/* Iconos */
.icon{
    font-size: 1.8rem;
    cursor: pointer;

}
.search-icon{
    color: #585858;
    position: absolute;
    right: 10px;
}
.add-icon{
    font-size: 1.5rem;
    color: white;
    position: absolute;
    right: 5px;
    filter: drop-shadow(0px 0px 5px #00c9a7);
    z-index: 2;
    
}
.btn-wrapper-search{
    position: relative;
    display: flex;
    align-items: center;
    width: 80%;
    
}
.btn-addNote{
    font-family: 'Montserrat', sans-serif;
    padding: 1rem;
    border-radius: 10px;
    border: none;
    outline: none;
    cursor: pointer;
    text-transform: uppercase;
    font-weight: bold;
 
    background-color: rgb(1, 1, 1, .8);
    color: white;
    width: 100%;


}

@media (min-width: 668px) { 
    .search{
        flex-direction: row;
        gap: 2.5rem;
        width: 90%;
    }
    .input-wrapper{
        width: 40%;
    }
    .btn-wrapper-search{
        width: 25%;
    }

}

.boxBTN:before {
content: "";
z-index: -1;
position: absolute;
top: 0;
right: 0;
bottom: 0;
left: 0;
background: linear-gradient(129deg, #00c9a7 0%, #845ec2 100% );
transform: translate3d(0px, 0px, 0) scale(1.05);
filter: blur(8px);
opacity: var(0.7);
transition: opacity 0.3s;
border-radius: inherit;
}

/* 
* Prevents issues when the parent creates a 
* stacking context. (For example, using the transform
* property )
*/
.boxBTN::after {
content: "";
z-index: -1;
position: absolute;
top: 0;
right: 0;
bottom: 0;
left: 0;
background: inherit;
border-radius: inherit;
}

.boxInput:before {
content: "";
z-index: -1;
position: absolute;
top: 0;
right: 0;
bottom: 0;
left: 0;
background: linear-gradient(129deg, #845ec2 0%, #00c9a7 100% );
transform: translate3d(0px, 0px, 0) scale(1.05);
filter: blur(8px);
opacity: var(0.7);
transition: opacity 0.3s;
border-radius: inherit;
}

/* 
* Prevents issues when the parent creates a 
* stacking context. (For example, using the transform
* property )
*/
.boxInput::after {
content: "";
z-index: -1;
position: absolute;
top: 0;
right: 0;
bottom: 0;
left: 0;
background: inherit;
border-radius: inherit;
}
    
